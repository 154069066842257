import React from 'react'
import { inject, observer } from 'mobx-react'
import { clinics } from 'shared/constants'

const HeaderCallMeBack = () => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="row select-wrapper">
                    {Object.entries(clinics).map(([key, value]) => (
                        <div className="select-wrapper__item" key={key}>
                            <span>{value.phones[0]}</span>
                            <span>{value.phones[1]}</span>
                            <span>{value.location}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default inject('store')(observer(HeaderCallMeBack))
